<template>
  <main className="py-5 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 min-h-screen">
    <PageHeader title="Wijzig project" />

    <div class="mt-10 sm:mt-0" v-if="!loading">
      <div class="md:grid md:grid-cols-3 md:gap-6">
        <div class="md:col-span-1">
          <div class="px-4 sm:px-0">
            <h3 class="text-lg font-medium leading-6 text-gray-900">General information</h3>
            <p class="mt-1 text-sm text-gray-600">
              More help here.
            </p>
          </div>
        </div>
        <div class="mt-5 md:mt-0 md:col-span-2">
          <form @submit.prevent="handleSave" method="POST">
            <div class="shadow sm:rounded-md">
              <div class="px-4 py-5 bg-white sm:p-6">
                <div class="grid grid-cols-6 gap-6">
                  
                  <div class="col-span-6 sm:col-span-6">
                    <label for="title" class="block text-sm font-medium text-gray-700">Rol</label>
                    <input type="text" name="title" id="title" autocomplete="title" class="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" v-model="data.title">
                  </div>

                  <div class="col-span-6 sm:col-span-6">
                    <label for="tags" class="block text-sm font-medium text-gray-700">Client</label>
                    <Multiselect
                      v-model="data.ClientId"
                      :options="clients"
                      :searchable="true"
                      required
                      :classes="{
                        container: 'relative mx-auto w-full flex items-center justify-end box-border cursor-pointer border border-gray-300 rounded bg-white text-base leading-snug outline-none',
                        containerDisabled: 'cursor-default bg-gray-100',
                        containerOpen: 'rounded-b-none',
                        containerOpenTop: 'rounded-t-none',
                        containerActive: 'ring ring-blue-500 ring-opacity-30',
                        singleLabel: 'flex items-center h-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-3.5',
                        multipleLabel: 'flex items-center h-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-3.5',
                        search: 'w-full absolute inset-0 outline-none appearance-none box-border border-0 text-base font-sans bg-white rounded pl-3.5',
                        tags: 'flex-grow flex-shrink flex flex-wrap items-center mt-1 pl-2',
                        tag: 'bg-blue-500 text-white text-sm font-semibold py-0.5 pl-2 rounded mr-1 mb-1 flex items-center whitespace-nowrap',
                        tagDisabled: 'pr-2 !bg-gray-400 text-white',
                        tagRemove: 'flex items-center justify-center p-1 mx-0.5 rounded-sm hover:bg-black hover:bg-opacity-10 group',
                        tagRemoveIcon: 'bg-multiselect-remove bg-center bg-no-repeat opacity-30 inline-block w-3 h-3 group-hover:opacity-60',
                        tagsSearchWrapper: 'inline-block relative mx-1 mb-1 flex-grow flex-shrink h-full',
                        tagsSearch: 'absolute inset-0 border-0 outline-none appearance-none p-0 text-base font-sans box-border w-full',
                        tagsSearchCopy: 'invisible whitespace-pre-wrap inline-block h-px',
                        placeholder: 'flex items-center h-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-3.5 text-gray-400',
                        caret: 'bg-multiselect-caret bg-center bg-no-repeat w-2.5 h-4 py-px box-content mr-3.5 relative z-10 opacity-40 flex-shrink-0 flex-grow-0 transition-transform transform pointer-events-none',
                        caretOpen: 'rotate-180 pointer-events-auto',
                        clear: 'pr-3.5 relative z-10 opacity-40 transition duration-300 flex-shrink-0 flex-grow-0 flex hover:opacity-80',
                        clearIcon: 'bg-multiselect-remove bg-center bg-no-repeat w-2.5 h-4 py-px box-content inline-block',
                        spinner: 'bg-multiselect-spinner bg-center bg-no-repeat w-4 h-4 z-10 mr-3.5 animate-spin flex-shrink-0 flex-grow-0',
                        dropdown: 'absolute -left-px -right-px bottom-0 transform translate-y-full border border-gray-300 -mt-px overflow-y-scroll z-50 bg-white flex flex-col rounded-b',
                        dropdownTop: '-translate-y-full top-px bottom-auto flex-col-reverse rounded-b-none rounded-t',
                        dropdownHidden: 'hidden',
                        options: 'flex flex-col p-0 m-0 list-none',
                        optionsTop: 'flex-col-reverse',
                        option: 'flex items-center justify-start box-border text-left cursor-pointer text-base leading-snug py-2 px-3',
                        optionPointed: 'text-gray-800 bg-gray-100',
                        optionSelected: 'text-white bg-blue-500',
                        optionDisabled: 'text-gray-300 cursor-not-allowed',
                        optionSelectedPointed: 'text-white bg-blue-500 opacity-90',
                        optionSelectedDisabled: 'text-blue-100 bg-blue-500 bg-opacity-50 cursor-not-allowed',
                        noOptions: 'py-2 px-3 text-gray-600 bg-white',
                        noResults: 'py-2 px-3 text-gray-600 bg-white',
                        fakeInput: 'bg-transparent absolute left-0 right-0 -bottom-px w-full h-px border-0 p-0 appearance-none outline-none text-transparent',
                        spacer: 'h-9 py-px box-content',
                      }"
                    />
                  </div>

                  <div class="col-span-6 sm:col-span-3">
                    <label for="from_date" class="block text-sm font-medium text-gray-700">From date (DD-MM-YYYY)</label>
                    <input type="text" name="from_date" id="from_date" autocomplete="from_date" class="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" v-model="data.from_date">
                  </div>

                  <div class="col-span-6 sm:col-span-3">
                    <label for="to_date" class="block text-sm font-medium text-gray-700">To date (DD-MM-YYYY)</label>
                    <input type="text" name="to_date" id="from_date" autocomplete="to_date" class="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" v-model="data.to_date">
                  </div>
                  
                  <div class="col-span-6 sm:col-span-6">
                    <label for="email_address" class="block text-sm font-medium text-gray-700">Description</label>
                    <TinyMceEditor v-model="data.description" :model_id="data.id" :simlpe="true" />
                  </div>
                  
                </div>
              </div>
              <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
                <button type="button" class="inline-flex items-center px-3 py-2 mx-4 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500" @click="destroy">
                  Delete
                </button>
                <button type="submit" class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-orange-600 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500">
                  <svg v-show="saving" class="animate-spin h-4 mr-2 w-4 rounded-full bg-transparent border-2 border-transparent border-opacity-50" style="border-right-color: white; border-top-color: white;" viewBox="0 0 24 24"></svg>
                  {{saving ? 'saving': 'save'}}
                </button>
              </div>
            </div>
          </form>
          
        </div>
      </div>
    </div>

    <div class="hidden sm:block" aria-hidden="true">
      <div class="py-5">
        <div class="border-t border-gray-200"></div>
      </div>
    </div>

  </main>
</template>

<script>
import { ref, onMounted } from "vue";
import { useRoute } from 'vue-router'
import CvService from '../../../../services/admin/cv/service';
import ClientService from '../../../../services/admin/clients/service';
import TinyMceEditor from '../../../../components/admin/generic/TinyMceEditor.vue';
import Multiselect from '@vueform/multiselect'
import moment from 'moment';

export default {
  name: 'EditProject',
  components: {
    TinyMceEditor,
    Multiselect
  },
  setup() {
    const data = ref(null);
    const loading = ref(true);
    const saving = ref(false);
    const error = ref(null);
    const clients = ref([])
    const route = useRoute()
    const id = route.params.id;

    const fetchAllClients = async function() {
      ClientService.getAllClients().then(({data}) => {
        clients.value = data.items.map((t)=> {
          return {
            value: t.id,
            label: t.title
          }
        })
      });
    }

    function fetchData() {
      loading.value = true;
      // I prefer to use fetch
      // you can use use axios as an alternative
      return CvService.getProject(id).then(res => {
          // a non-200 response code
          if (res.status !== 200) {
            // create error instance with HTTP status text
            const error = new Error(res.statusText);
            error.json = res.json();
            throw error;
          }

          return res.data;
        })
        .then(json => {
          // set the response data
          data.value = json;
          data.value.from_date = moment(json.from_date).format('DD-MM-YYYY');
          data.value.to_date = json.to_date ? moment(json.to_date).format('DD-MM-YYYY') : '';
        })
        .catch(err => {
          error.value = err;
          // In case a custom JSON error response was provided
          if (err.json) {
            return err.json.then(json => {
              // set the JSON response message
              error.value.message = json.message;
            });
          }
        })
        .then(() => {
          loading.value = false;
        });
    }
    onMounted(() => {
      fetchAllClients();
      fetchData();
    });

    return {
      data,
      clients,
      loading,
      saving,
      error
    };
  },
  methods: {
    async handleSave() {
      this.saving = true;
      try {
        await CvService.saveProject(this.data);
        this.emitter.emit('notification', {type: 'success', message: 'Updated', description: 'The project has been saved succesfully'});
      } catch (err) {
        this.emitter.emit('notification', {type: 'danger', message: 'Experienced an error', description: 'I\'m sorry to say I experienced an issue', duration: 15000});
      }
      this.saving = false;
    },
    async destroy() {
      if(confirm('are you sure?')) {
        this.loading = true;
        await CvService.removeProject(this.data.id);
        this.emitter.emit('notification', {type: 'success', message: 'Deleted', description: 'You deleted the project.'});
        this.$router.push(`/cv/projects/${this.data.UserId}`);
        this.loading = false;
      }
    }
  }
}
</script>